/* eslint-disable max-len */
/* eslint-disable max-len */
/* eslint-disable max-len */
/* eslint-disable max-len */

<template>
  <div>
    <svg
      v-if="name == '61'"
      id="Group_61"
      data-name="Group 61"
      xmlns="http://www.w3.org/2000/svg"
      width="342.093"
      height="169.159"
      viewBox="0 0 342.093 169.159"
    >
      <g id="Group_17" data-name="Group 17" transform="translate(0 23.227)">
        <g id="Group_16" data-name="Group 16">
          <g id="Group_15" data-name="Group 15">
            <g id="Group_14" data-name="Group 14">
              <path
                id="Rectangle_59"
                data-name="Rectangle 59"
                d="M7.258,0H334.836a7.257,7.257,0,0,1,7.257,7.257V18.536a7.257,7.257,0,0,1-7.257,7.257H7.257A7.257,7.257,0,0,1,0,18.536V7.258A7.258,7.258,0,0,1,7.258,0Z"
                fill="#f1f0f0"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_18" data-name="Group 18" transform="translate(135.473 0)">
        <path
          id="Path_39"
          data-name="Path 39"
          d="M338.48,557.593a1.676,1.676,0,0,0-1.437.7,3.245,3.245,0,0,0-.509,1.944q0,2.593,1.946,2.593a6.15,6.15,0,0,0,1.978-.409V563.8a5.5,5.5,0,0,1-2.132.4,3.251,3.251,0,0,1-2.587-1.026,4.344,4.344,0,0,1-.9-2.946,4.8,4.8,0,0,1,.44-2.118,3.183,3.183,0,0,1,1.265-1.4,3.745,3.745,0,0,1,1.933-.485,5.22,5.22,0,0,1,2.269.546l-.53,1.336a8.864,8.864,0,0,0-.875-.361A2.611,2.611,0,0,0,338.48,557.593Z"
          transform="translate(-334.842 -556.214)"
          fill="#595a5a"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M353.686,560.195a4.207,4.207,0,0,1-.954,2.959,4.139,4.139,0,0,1-5.473,0,4.225,4.225,0,0,1-.954-2.97,4.164,4.164,0,0,1,.957-2.956,3.579,3.579,0,0,1,2.744-1.021,3.529,3.529,0,0,1,2.733,1.028A4.221,4.221,0,0,1,353.686,560.195Zm-5.658,0a3.238,3.238,0,0,0,.493,1.956,1.726,1.726,0,0,0,1.474.657q1.966,0,1.967-2.613t-1.957-2.62a1.737,1.737,0,0,0-1.48.661A3.224,3.224,0,0,0,348.027,560.195Z"
          transform="translate(-339.514 -556.207)"
          fill="#595a5a"
        />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M361.789,564.165v-7.752h1.644v6.395h3.145v1.358Z"
          transform="translate(-345.826 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_42"
          data-name="Path 42"
          d="M378.4,556.413v5.016a2.894,2.894,0,0,1-.385,1.506,2.5,2.5,0,0,1-1.111.992,3.971,3.971,0,0,1-1.718.345,3.291,3.291,0,0,1-2.323-.766,2.72,2.72,0,0,1-.827-2.1v-4.995h1.638v4.745a1.989,1.989,0,0,0,.36,1.316,1.5,1.5,0,0,0,1.194.418,1.466,1.466,0,0,0,1.169-.421,2,2,0,0,0,.364-1.323v-4.735Z"
          transform="translate(-350.005 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_43"
          data-name="Path 43"
          d="M389.176,564.165l-1.867-6.083h-.048q.1,1.856.1,2.477v3.606h-1.469v-7.752h2.237l1.835,5.928H390l1.947-5.928h2.237v7.752h-1.533V560.5c0-.173,0-.373.008-.6s.03-.828.072-1.8h-.048l-2,6.072Z"
          transform="translate(-355.652 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_44"
          data-name="Path 44"
          d="M409.984,564.165h-2.089l-3.372-5.865h-.048q.1,1.554.1,2.216v3.649h-1.469v-7.752h2.073l3.368,5.806h.037q-.079-1.512-.079-2.137v-3.669h1.48Z"
          transform="translate(-362.669 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_45"
          data-name="Path 45"
          d="M429.408,560.195a4.208,4.208,0,0,1-.954,2.959,4.138,4.138,0,0,1-5.472,0,4.222,4.222,0,0,1-.955-2.97,4.163,4.163,0,0,1,.958-2.956,3.577,3.577,0,0,1,2.744-1.021,3.53,3.53,0,0,1,2.734,1.028A4.22,4.22,0,0,1,429.408,560.195Zm-5.658,0a3.234,3.234,0,0,0,.493,1.956,1.725,1.725,0,0,0,1.474.657q1.967,0,1.967-2.613t-1.957-2.62a1.737,1.737,0,0,0-1.48.661A3.224,3.224,0,0,0,423.75,560.195Z"
          transform="translate(-370.381 -556.207)"
          fill="#595a5a"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M444.389,564.165H442.3l-3.372-5.865h-.048q.1,1.554.1,2.216v3.649h-1.469v-7.752h2.073l3.367,5.806h.037q-.08-1.512-.08-2.137v-3.669h1.48Z"
          transform="translate(-376.693 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M456.881,564.165h-4.465v-7.752h4.465v1.346H454.06v1.7h2.625v1.347H454.06v2h2.822Z"
          transform="translate(-382.769 -556.291)"
          fill="#595a5a"
        />
      </g>
      <g id="Group_19" data-name="Group 19" transform="translate(247.56 0)">
        <path
          id="Path_48"
          data-name="Path 48"
          d="M527.7,557.593a1.678,1.678,0,0,0-1.437.7,3.25,3.25,0,0,0-.509,1.944q0,2.593,1.946,2.593a6.153,6.153,0,0,0,1.979-.409V563.8a5.5,5.5,0,0,1-2.132.4,3.253,3.253,0,0,1-2.588-1.026,4.343,4.343,0,0,1-.9-2.946,4.8,4.8,0,0,1,.44-2.118,3.18,3.18,0,0,1,1.264-1.4,3.747,3.747,0,0,1,1.933-.485,5.222,5.222,0,0,1,2.27.546l-.53,1.336a8.924,8.924,0,0,0-.875-.361A2.613,2.613,0,0,0,527.7,557.593Z"
          transform="translate(-524.06 -556.214)"
          fill="#595a5a"
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M542.9,560.195a4.208,4.208,0,0,1-.954,2.959,4.138,4.138,0,0,1-5.472,0,4.225,4.225,0,0,1-.955-2.97,4.164,4.164,0,0,1,.958-2.956,3.577,3.577,0,0,1,2.744-1.021,3.53,3.53,0,0,1,2.734,1.028A4.22,4.22,0,0,1,542.9,560.195Zm-5.658,0a3.234,3.234,0,0,0,.493,1.956,1.725,1.725,0,0,0,1.474.657q1.967,0,1.967-2.613t-1.957-2.62a1.737,1.737,0,0,0-1.48.661A3.225,3.225,0,0,0,537.245,560.195Z"
          transform="translate(-528.732 -556.207)"
          fill="#595a5a"
        />
        <path
          id="Path_50"
          data-name="Path 50"
          d="M551.007,564.165v-7.752h1.644v6.395H555.8v1.358Z"
          transform="translate(-535.044 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_51"
          data-name="Path 51"
          d="M567.619,556.413v5.016a2.889,2.889,0,0,1-.385,1.506,2.5,2.5,0,0,1-1.111.992,3.973,3.973,0,0,1-1.719.345,3.292,3.292,0,0,1-2.323-.766,2.722,2.722,0,0,1-.827-2.1v-4.995h1.638v4.745a1.99,1.99,0,0,0,.361,1.316,1.5,1.5,0,0,0,1.193.418,1.466,1.466,0,0,0,1.169-.421,2,2,0,0,0,.363-1.323v-4.735Z"
          transform="translate(-539.222 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M578.393,564.165l-1.867-6.083h-.047q.1,1.856.1,2.477v3.606h-1.469v-7.752h2.238l1.835,5.928h.032l1.946-5.928H583.4v7.752h-1.532V560.5c0-.173,0-.373.008-.6s.03-.828.072-1.8H581.9l-2,6.072Z"
          transform="translate(-544.87 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_53"
          data-name="Path 53"
          d="M599.2,564.165h-2.09L593.74,558.3h-.048q.1,1.554.1,2.216v3.649h-1.469v-7.752H594.4l3.368,5.806h.037q-.08-1.512-.08-2.137v-3.669h1.48Z"
          transform="translate(-551.886 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_54"
          data-name="Path 54"
          d="M614.294,564.165h-1.643v-6.385h-2.106v-1.368H616.4v1.368h-2.105Z"
          transform="translate(-559.314 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_55"
          data-name="Path 55"
          d="M629.011,564.165h-1.872l-1.05-4.073c-.039-.145-.1-.445-.2-.9s-.147-.759-.161-.915q-.032.286-.16.92c-.084.422-.15.723-.2.9l-1.044,4.062h-1.867l-1.978-7.752H622.1l.992,4.231q.259,1.173.376,2.031c.022-.2.071-.514.146-.935s.148-.751.215-.984l1.129-4.343h1.554l1.13,4.343c.048.194.111.491.186.891s.13.742.169,1.028q.053-.413.17-1.031t.212-1l.986-4.231h1.618Z"
          transform="translate(-563.365 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_56"
          data-name="Path 56"
          d="M646.123,560.195a4.207,4.207,0,0,1-.954,2.959,4.139,4.139,0,0,1-5.473,0,4.225,4.225,0,0,1-.954-2.97,4.163,4.163,0,0,1,.957-2.956,3.58,3.58,0,0,1,2.744-1.021,3.528,3.528,0,0,1,2.733,1.028A4.218,4.218,0,0,1,646.123,560.195Zm-5.658,0a3.241,3.241,0,0,0,.494,1.956,1.726,1.726,0,0,0,1.474.657q1.967,0,1.967-2.613t-1.957-2.62a1.738,1.738,0,0,0-1.48.661A3.227,3.227,0,0,0,640.465,560.195Z"
          transform="translate(-570.808 -556.207)"
          fill="#595a5a"
        />
      </g>
      <g
        id="Group_22"
        data-name="Group 22"
        transform="translate(27.889 31.629)"
      >
        <path
          id="Path_78"
          data-name="Path 78"
          d="M154.128,617.721h-.9v-7.753h4.322v.8h-3.42v2.837h3.214v.8h-3.214Z"
          transform="translate(-153.226 -609.751)"
          fill="#595a5a"
        />
        <path
          id="Path_79"
          data-name="Path 79"
          d="M162.046,610.184a.583.583,0,0,1,.149-.443.549.549,0,0,1,.737,0,.573.573,0,0,1,.154.44.583.583,0,0,1-.154.443.514.514,0,0,1-.366.146.508.508,0,0,1-.371-.146A.593.593,0,0,1,162.046,610.184Zm.954,7.387h-.88v-5.812H163Z"
          transform="translate(-156.821 -609.601)"
          fill="#595a5a"
        />
        <path
          id="Path_80"
          data-name="Path 80"
          d="M169.423,613.065a3.423,3.423,0,0,1,.694.064l-.122.816a3.018,3.018,0,0,0-.636-.079,1.546,1.546,0,0,0-1.206.573,2.086,2.086,0,0,0-.5,1.426v3.118h-.88v-5.812h.727l.1,1.077h.043a2.552,2.552,0,0,1,.78-.876A1.752,1.752,0,0,1,169.423,613.065Z"
          transform="translate(-158.747 -611.013)"
          fill="#595a5a"
        />
        <path
          id="Path_81"
          data-name="Path 81"
          d="M178.281,617.4a1.456,1.456,0,0,1-.6,1.251,2.838,2.838,0,0,1-1.7.44,3.693,3.693,0,0,1-1.8-.366v-.817a4.4,4.4,0,0,0,.9.334,3.758,3.758,0,0,0,.926.122,2.1,2.1,0,0,0,1.06-.22.758.758,0,0,0,.076-1.252,4.644,4.644,0,0,0-1.148-.569,6.215,6.215,0,0,1-1.153-.528,1.579,1.579,0,0,1-.509-.512,1.335,1.335,0,0,1-.167-.684,1.3,1.3,0,0,1,.578-1.121,2.693,2.693,0,0,1,1.585-.411,4.637,4.637,0,0,1,1.835.382l-.313.715a4.214,4.214,0,0,0-1.586-.36,1.82,1.82,0,0,0-.944.2.6.6,0,0,0-.318.541.661.661,0,0,0,.119.4,1.171,1.171,0,0,0,.384.312,8.634,8.634,0,0,0,1.018.43,3.786,3.786,0,0,1,1.4.758A1.338,1.338,0,0,1,178.281,617.4Z"
          transform="translate(-161.759 -611.013)"
          fill="#595a5a"
        />
        <path
          id="Path_82"
          data-name="Path 82"
          d="M185.284,617.509a2.87,2.87,0,0,0,.451-.034,3.058,3.058,0,0,0,.345-.072v.674a1.618,1.618,0,0,1-.422.114,3.149,3.149,0,0,1-.5.045q-1.686,0-1.686-1.776V613h-.832v-.424l.832-.366.371-1.24h.509v1.346h1.686V613h-1.686v3.42a1.174,1.174,0,0,0,.249.806A.864.864,0,0,0,185.284,617.509Z"
          transform="translate(-165.215 -610.16)"
          fill="#595a5a"
        />
        <path
          id="Path_83"
          data-name="Path 83"
          d="M195.361,617.721h-.9v-7.753h4.322v.8h-3.42v2.837h3.214v.8h-3.214Z"
          transform="translate(-170.034 -609.751)"
          fill="#595a5a"
        />
        <path
          id="Path_84"
          data-name="Path 84"
          d="M205.637,619.089a2.669,2.669,0,0,1-2.034-.785,3.034,3.034,0,0,1-.745-2.179,3.369,3.369,0,0,1,.693-2.233,2.3,2.3,0,0,1,1.859-.828,2.189,2.189,0,0,1,1.728.719,2.751,2.751,0,0,1,.637,1.9v.556h-4a2.3,2.3,0,0,0,.517,1.554,1.789,1.789,0,0,0,1.381.531,4.679,4.679,0,0,0,1.856-.393v.785a4.684,4.684,0,0,1-.883.289A4.949,4.949,0,0,1,205.637,619.089ZM205.4,613.8a1.435,1.435,0,0,0-1.116.456,2.1,2.1,0,0,0-.49,1.262h3.038a1.937,1.937,0,0,0-.371-1.275A1.307,1.307,0,0,0,205.4,613.8Z"
          transform="translate(-173.457 -611.013)"
          fill="#595a5a"
        />
        <path
          id="Path_85"
          data-name="Path 85"
          d="M216.984,618.991l-.175-.827h-.043a2.458,2.458,0,0,1-.867.74,2.644,2.644,0,0,1-1.079.193,1.94,1.94,0,0,1-1.355-.446,1.621,1.621,0,0,1-.49-1.267q0-1.761,2.816-1.845l.986-.032v-.361a1.473,1.473,0,0,0-.294-1.01,1.21,1.21,0,0,0-.941-.326,3.835,3.835,0,0,0-1.644.445l-.27-.674a4.226,4.226,0,0,1,1.967-.5,2.216,2.216,0,0,1,1.541.461,1.927,1.927,0,0,1,.5,1.479v3.967Zm-1.989-.621a1.787,1.787,0,0,0,1.291-.451,1.668,1.668,0,0,0,.469-1.262v-.524l-.88.037a3.189,3.189,0,0,0-1.514.326.983.983,0,0,0-.464.9.9.9,0,0,0,.289.726A1.2,1.2,0,0,0,214.995,618.37Z"
          transform="translate(-177.581 -611.02)"
          fill="#595a5a"
        />
        <path
          id="Path_86"
          data-name="Path 86"
          d="M225.363,617.509a2.863,2.863,0,0,0,.45-.034,3.028,3.028,0,0,0,.345-.072v.674a1.612,1.612,0,0,1-.422.114,3.147,3.147,0,0,1-.5.045q-1.687,0-1.686-1.776V613h-.833v-.424l.833-.366.371-1.24h.509v1.346h1.686V613h-1.686v3.42a1.174,1.174,0,0,0,.249.806A.864.864,0,0,0,225.363,617.509Z"
          transform="translate(-181.552 -610.16)"
          fill="#595a5a"
        />
        <path
          id="Path_87"
          data-name="Path 87"
          d="M231.655,613.244v3.77a1.515,1.515,0,0,0,.323,1.06,1.317,1.317,0,0,0,1.013.35,1.652,1.652,0,0,0,1.333-.5,2.51,2.51,0,0,0,.422-1.628v-3.054h.88v5.812H234.9l-.127-.78h-.048a1.748,1.748,0,0,1-.75.658,2.528,2.528,0,0,1-1.1.228,2.22,2.22,0,0,1-1.588-.5,2.142,2.142,0,0,1-.528-1.612v-3.8Z"
          transform="translate(-184.833 -611.086)"
          fill="#595a5a"
        />
        <path
          id="Path_88"
          data-name="Path 88"
          d="M244.977,613.065a3.433,3.433,0,0,1,.695.064l-.122.816a3.027,3.027,0,0,0-.637-.079,1.547,1.547,0,0,0-1.206.573,2.086,2.086,0,0,0-.5,1.426v3.118h-.88v-5.812h.726l.1,1.077h.042a2.551,2.551,0,0,1,.78-.876A1.755,1.755,0,0,1,244.977,613.065Z"
          transform="translate(-189.546 -611.013)"
          fill="#595a5a"
        />
        <path
          id="Path_89"
          data-name="Path 89"
          d="M252.574,619.089a2.668,2.668,0,0,1-2.034-.785,3.034,3.034,0,0,1-.745-2.179,3.372,3.372,0,0,1,.692-2.233,2.3,2.3,0,0,1,1.859-.828,2.191,2.191,0,0,1,1.729.719,2.754,2.754,0,0,1,.637,1.9v.556h-4a2.3,2.3,0,0,0,.517,1.554,1.789,1.789,0,0,0,1.382.531,4.679,4.679,0,0,0,1.856-.393v.785a4.7,4.7,0,0,1-.883.289A4.948,4.948,0,0,1,252.574,619.089Zm-.239-5.286a1.434,1.434,0,0,0-1.116.456,2.1,2.1,0,0,0-.49,1.262h3.038a1.934,1.934,0,0,0-.371-1.275A1.306,1.306,0,0,0,252.335,613.8Z"
          transform="translate(-192.59 -611.013)"
          fill="#595a5a"
        />
      </g>
      <g
        id="Group_23"
        data-name="Group 23"
        transform="translate(19.917 61.243)"
      >
        <path
          id="Path_90"
          data-name="Path 90"
          d="M144.647,666.048a1.9,1.9,0,0,1-.742,1.6,3.218,3.218,0,0,1-2.015.573,5.046,5.046,0,0,1-2.121-.355v-.869a5.619,5.619,0,0,0,1.039.318,5.486,5.486,0,0,0,1.114.116,2.249,2.249,0,0,0,1.358-.342,1.123,1.123,0,0,0,.456-.951,1.229,1.229,0,0,0-.162-.661,1.51,1.51,0,0,0-.541-.475,7.441,7.441,0,0,0-1.153-.493,3.687,3.687,0,0,1-1.546-.918,2.025,2.025,0,0,1-.464-1.384,1.719,1.719,0,0,1,.674-1.426,2.8,2.8,0,0,1,1.781-.531,5.247,5.247,0,0,1,2.127.424l-.281.785a4.813,4.813,0,0,0-1.867-.4,1.806,1.806,0,0,0-1.119.308,1.013,1.013,0,0,0-.4.854,1.306,1.306,0,0,0,.149.661,1.393,1.393,0,0,0,.5.472,6.249,6.249,0,0,0,1.079.474,4.265,4.265,0,0,1,1.678.934A1.835,1.835,0,0,1,144.647,666.048Z"
          transform="translate(-139.769 -659.86)"
          fill="#595a5a"
        />
        <path
          id="Path_91"
          data-name="Path 91"
          d="M152.928,669.556a2.671,2.671,0,0,1-2.034-.784,3.039,3.039,0,0,1-.745-2.18,3.368,3.368,0,0,1,.692-2.232,2.3,2.3,0,0,1,1.859-.827,2.191,2.191,0,0,1,1.728.718,2.754,2.754,0,0,1,.637,1.9v.557h-4a2.3,2.3,0,0,0,.517,1.553,1.788,1.788,0,0,0,1.381.53,4.677,4.677,0,0,0,1.856-.392v.785a4.688,4.688,0,0,1-.883.289A4.994,4.994,0,0,1,152.928,669.556Zm-.238-5.287a1.437,1.437,0,0,0-1.117.457,2.1,2.1,0,0,0-.491,1.262h3.039a1.936,1.936,0,0,0-.371-1.275A1.308,1.308,0,0,0,152.69,664.269Z"
          transform="translate(-144 -661.199)"
          fill="#595a5a"
        />
        <path
          id="Path_92"
          data-name="Path 92"
          d="M163.1,669.556a2.481,2.481,0,0,1-1.954-.777,3.194,3.194,0,0,1-.692-2.2,3.293,3.293,0,0,1,.7-2.254,2.53,2.53,0,0,1,2-.8,3.955,3.955,0,0,1,.838.091,2.722,2.722,0,0,1,.657.212l-.27.748a4.128,4.128,0,0,0-.636-.194,2.862,2.862,0,0,0-.61-.077q-1.771,0-1.771,2.259a2.688,2.688,0,0,0,.432,1.644,1.5,1.5,0,0,0,1.281.572,3.9,3.9,0,0,0,1.49-.313v.78A3.177,3.177,0,0,1,163.1,669.556Z"
          transform="translate(-148.201 -661.199)"
          fill="#595a5a"
        />
        <path
          id="Path_93"
          data-name="Path 93"
          d="M174.962,666.538a3.211,3.211,0,0,1-.716,2.22,2.523,2.523,0,0,1-1.979.8,2.619,2.619,0,0,1-1.384-.365,2.415,2.415,0,0,1-.933-1.05,3.655,3.655,0,0,1-.329-1.6,3.2,3.2,0,0,1,.71-2.214,2.515,2.515,0,0,1,1.973-.792,2.458,2.458,0,0,1,1.938.811A3.193,3.193,0,0,1,174.962,666.538Zm-4.428,0a2.764,2.764,0,0,0,.445,1.7,1.768,1.768,0,0,0,2.622,0,2.744,2.744,0,0,0,.448-1.7,2.7,2.7,0,0,0-.448-1.683,1.574,1.574,0,0,0-1.323-.575,1.548,1.548,0,0,0-1.3.567A2.734,2.734,0,0,0,170.534,666.538Z"
          transform="translate(-151.938 -661.199)"
          fill="#595a5a"
        />
        <path
          id="Path_94"
          data-name="Path 94"
          d="M185.6,669.45v-3.759a1.521,1.521,0,0,0-.323-1.061,1.319,1.319,0,0,0-1.013-.349,1.67,1.67,0,0,0-1.336.493,2.488,2.488,0,0,0-.424,1.628v3.049h-.88v-5.812h.716l.143.8h.043a1.783,1.783,0,0,1,.758-.665,2.459,2.459,0,0,1,1.087-.236,2.209,2.209,0,0,1,1.58.506,2.158,2.158,0,0,1,.53,1.62v3.791Z"
          transform="translate(-156.831 -661.199)"
          fill="#595a5a"
        />
        <path
          id="Path_95"
          data-name="Path 95"
          d="M196.8,667.065h-.048a2.068,2.068,0,0,1-1.824.885,2.158,2.158,0,0,1-1.774-.779,3.434,3.434,0,0,1-.633-2.217,3.488,3.488,0,0,1,.636-2.232,2.142,2.142,0,0,1,1.771-.8,2.116,2.116,0,0,1,1.814.859h.069l-.037-.419-.021-.408v-2.365h.88v8.251h-.716Zm-1.761.148a1.6,1.6,0,0,0,1.307-.49,2.48,2.48,0,0,0,.405-1.583v-.186a2.92,2.92,0,0,0-.411-1.763,1.561,1.561,0,0,0-1.313-.528,1.346,1.346,0,0,0-1.185.6,2.993,2.993,0,0,0-.411,1.7,2.866,2.866,0,0,0,.408,1.681A1.38,1.38,0,0,0,195.035,667.214Z"
          transform="translate(-161.27 -659.594)"
          fill="#595a5a"
        />
      </g>
      <g
        id="Group_24"
        data-name="Group 24"
        transform="translate(60.468 61.742)"
      >
        <path
          id="Path_96"
          data-name="Path 96"
          d="M209.125,668.187h-.9v-7.752h4.321v.8h-3.42v2.837h3.214v.8h-3.214Z"
          transform="translate(-208.224 -660.435)"
          fill="#595a5a"
        />
        <path
          id="Path_97"
          data-name="Path 97"
          d="M219.4,669.556a2.67,2.67,0,0,1-2.034-.784,3.037,3.037,0,0,1-.745-2.18,3.37,3.37,0,0,1,.691-2.232,2.3,2.3,0,0,1,1.859-.827,2.194,2.194,0,0,1,1.729.718,2.756,2.756,0,0,1,.636,1.9v.557h-4a2.3,2.3,0,0,0,.517,1.553,1.787,1.787,0,0,0,1.381.53,4.679,4.679,0,0,0,1.856-.392v.785a4.688,4.688,0,0,1-.883.289A4.994,4.994,0,0,1,219.4,669.556Zm-.238-5.287a1.437,1.437,0,0,0-1.117.457,2.1,2.1,0,0,0-.49,1.262h3.039a1.936,1.936,0,0,0-.371-1.275A1.308,1.308,0,0,0,219.163,664.269Z"
          transform="translate(-211.648 -661.698)"
          fill="#595a5a"
        />
        <path
          id="Path_98"
          data-name="Path 98"
          d="M230.747,669.457l-.175-.827h-.042a2.463,2.463,0,0,1-.867.74,2.643,2.643,0,0,1-1.079.193,1.938,1.938,0,0,1-1.355-.445,1.623,1.623,0,0,1-.49-1.267q0-1.762,2.816-1.846l.986-.032v-.361a1.474,1.474,0,0,0-.294-1.01,1.211,1.211,0,0,0-.941-.326,3.835,3.835,0,0,0-1.644.445l-.271-.673a4.156,4.156,0,0,1,.941-.367,4.106,4.106,0,0,1,1.026-.132,2.215,2.215,0,0,1,1.541.461,1.925,1.925,0,0,1,.5,1.479v3.966Zm-1.989-.62a1.789,1.789,0,0,0,1.291-.451,1.669,1.669,0,0,0,.469-1.262V666.6l-.88.037a3.2,3.2,0,0,0-1.514.326.983.983,0,0,0-.464.9.908.908,0,0,0,.289.727A1.2,1.2,0,0,0,228.759,668.837Z"
          transform="translate(-215.771 -661.705)"
          fill="#595a5a"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M239.126,667.976a2.857,2.857,0,0,0,.451-.034,2.9,2.9,0,0,0,.345-.072v.673a1.6,1.6,0,0,1-.421.114,3.155,3.155,0,0,1-.5.045q-1.686,0-1.686-1.776v-3.458h-.833v-.424l.833-.366.371-1.24h.509v1.346h1.686v.684h-1.686v3.42a1.179,1.179,0,0,0,.249.806A.868.868,0,0,0,239.126,667.976Z"
          transform="translate(-219.742 -660.844)"
          fill="#595a5a"
        />
        <path
          id="Path_100"
          data-name="Path 100"
          d="M245.419,663.711v3.77a1.515,1.515,0,0,0,.323,1.06,1.318,1.318,0,0,0,1.013.35,1.65,1.65,0,0,0,1.333-.5,2.51,2.51,0,0,0,.422-1.628v-3.054h.88v5.812h-.726l-.127-.779h-.048a1.752,1.752,0,0,1-.751.657,2.518,2.518,0,0,1-1.095.228,2.221,2.221,0,0,1-1.588-.5,2.144,2.144,0,0,1-.528-1.612v-3.8Z"
          transform="translate(-223.023 -661.77)"
          fill="#595a5a"
        />
        <path
          id="Path_101"
          data-name="Path 101"
          d="M258.741,663.532a3.439,3.439,0,0,1,.695.064l-.122.816a3.019,3.019,0,0,0-.637-.079,1.548,1.548,0,0,0-1.206.573,2.087,2.087,0,0,0-.5,1.426v3.118h-.88v-5.812h.726l.1,1.076h.043a2.544,2.544,0,0,1,.78-.874A1.755,1.755,0,0,1,258.741,663.532Z"
          transform="translate(-227.736 -661.698)"
          fill="#595a5a"
        />
        <path
          id="Path_102"
          data-name="Path 102"
          d="M266.338,669.556a2.67,2.67,0,0,1-2.034-.784,3.037,3.037,0,0,1-.745-2.18,3.37,3.37,0,0,1,.692-2.232,2.3,2.3,0,0,1,1.859-.827,2.193,2.193,0,0,1,1.729.718,2.756,2.756,0,0,1,.636,1.9v.557h-4a2.3,2.3,0,0,0,.517,1.553,1.786,1.786,0,0,0,1.381.53,4.674,4.674,0,0,0,1.855-.392v.785a4.66,4.66,0,0,1-.883.289A4.992,4.992,0,0,1,266.338,669.556Zm-.239-5.287a1.436,1.436,0,0,0-1.116.457,2.1,2.1,0,0,0-.491,1.262h3.038a1.936,1.936,0,0,0-.371-1.275A1.308,1.308,0,0,0,266.1,664.269Z"
          transform="translate(-230.78 -661.698)"
          fill="#595a5a"
        />
      </g>
      <g id="Group_28" data-name="Group 28" transform="translate(0 83.636)">
        <g id="Group_27" data-name="Group 27">
          <g id="Group_26" data-name="Group 26">
            <g id="Group_25" data-name="Group 25">
              <path
                id="Rectangle_60"
                data-name="Rectangle 60"
                d="M7.258,0H334.836a7.257,7.257,0,0,1,7.257,7.257V18.535a7.258,7.258,0,0,1-7.258,7.258H7.257A7.257,7.257,0,0,1,0,18.536V7.258A7.258,7.258,0,0,1,7.258,0Z"
                fill="#f1f0f0"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_29"
        data-name="Group 29"
        transform="translate(24.882 91.138)"
      >
        <path
          id="Path_103"
          data-name="Path 103"
          d="M151.507,718.653h-.9V711.7H148.15v-.8h5.812v.8h-2.455Z"
          transform="translate(-148.15 -710.402)"
          fill="#595a5a"
        />
        <path
          id="Path_104"
          data-name="Path 104"
          d="M163.85,718.31v-3.759a1.517,1.517,0,0,0-.323-1.061,1.317,1.317,0,0,0-1.012-.35,1.661,1.661,0,0,0-1.339.5,2.528,2.528,0,0,0-.422,1.634v3.038h-.88v-8.25h.88v2.5a5.537,5.537,0,0,1-.042.748h.053a1.78,1.78,0,0,1,.739-.66,2.393,2.393,0,0,1,1.1-.242,2.246,2.246,0,0,1,1.6.506,2.132,2.132,0,0,1,.533,1.609v3.791Z"
          transform="translate(-152.929 -710.06)"
          fill="#595a5a"
        />
        <path
          id="Path_105"
          data-name="Path 105"
          d="M171.2,711.117a.583.583,0,0,1,.149-.442.55.55,0,0,1,.737,0,.575.575,0,0,1,.154.44.583.583,0,0,1-.154.443.513.513,0,0,1-.366.146.505.505,0,0,1-.371-.146A.593.593,0,0,1,171.2,711.117Zm.954,7.387h-.88v-5.812h.88Z"
          transform="translate(-157.546 -710.253)"
          fill="#595a5a"
        />
        <path
          id="Path_106"
          data-name="Path 106"
          d="M178.56,714a3.426,3.426,0,0,1,.695.064l-.122.816a2.975,2.975,0,0,0-.636-.079,1.551,1.551,0,0,0-1.207.572,2.093,2.093,0,0,0-.5,1.427v3.118h-.88V714.1h.726l.1,1.076h.042a2.549,2.549,0,0,1,.78-.874A1.757,1.757,0,0,1,178.56,714Z"
          transform="translate(-159.465 -711.665)"
          fill="#595a5a"
        />
        <path
          id="Path_107"
          data-name="Path 107"
          d="M187.657,717.531h-.048a2.067,2.067,0,0,1-1.824.885,2.157,2.157,0,0,1-1.774-.78,3.431,3.431,0,0,1-.633-2.217,3.489,3.489,0,0,1,.636-2.232,2.14,2.14,0,0,1,1.771-.8,2.113,2.113,0,0,1,1.813.859h.069l-.037-.419-.021-.408V710.06h.88v8.25h-.716Zm-1.761.148a1.6,1.6,0,0,0,1.307-.49,2.477,2.477,0,0,0,.406-1.583v-.186a2.92,2.92,0,0,0-.411-1.763,1.562,1.562,0,0,0-1.313-.528,1.346,1.346,0,0,0-1.185.6,2.99,2.99,0,0,0-.411,1.7,2.871,2.871,0,0,0,.408,1.681A1.382,1.382,0,0,0,185.9,717.68Z"
          transform="translate(-162.51 -710.06)"
          fill="#595a5a"
        />
        <path
          id="Path_108"
          data-name="Path 108"
          d="M199.946,718.653h-.9V710.9h4.322v.8h-3.42v2.837h3.214v.8h-3.214Z"
          transform="translate(-168.896 -710.402)"
          fill="#595a5a"
        />
        <path
          id="Path_109"
          data-name="Path 109"
          d="M210.222,720.022a2.671,2.671,0,0,1-2.034-.784,3.041,3.041,0,0,1-.745-2.18,3.37,3.37,0,0,1,.692-2.233,2.3,2.3,0,0,1,1.858-.827,2.19,2.19,0,0,1,1.729.719,2.752,2.752,0,0,1,.637,1.9v.557h-4a2.306,2.306,0,0,0,.517,1.554,1.791,1.791,0,0,0,1.381.53,4.688,4.688,0,0,0,1.856-.392v.785a4.618,4.618,0,0,1-.883.288A4.936,4.936,0,0,1,210.222,720.022Zm-.239-5.287a1.436,1.436,0,0,0-1.116.457,2.094,2.094,0,0,0-.49,1.262h3.038a1.938,1.938,0,0,0-.371-1.275A1.308,1.308,0,0,0,209.983,714.735Z"
          transform="translate(-172.32 -711.665)"
          fill="#595a5a"
        />
        <path
          id="Path_110"
          data-name="Path 110"
          d="M221.568,719.923l-.175-.827h-.043a2.457,2.457,0,0,1-.867.74,2.641,2.641,0,0,1-1.079.193,1.937,1.937,0,0,1-1.355-.445,1.622,1.622,0,0,1-.49-1.267q0-1.761,2.816-1.845l.986-.032v-.361a1.474,1.474,0,0,0-.294-1.01,1.21,1.21,0,0,0-.941-.327,3.836,3.836,0,0,0-1.644.445l-.27-.673a4.128,4.128,0,0,1,.941-.366,4.086,4.086,0,0,1,1.027-.133,2.211,2.211,0,0,1,1.54.461,1.925,1.925,0,0,1,.5,1.479v3.967Zm-1.989-.62a1.785,1.785,0,0,0,1.291-.451,1.667,1.667,0,0,0,.469-1.262v-.525l-.88.037a3.207,3.207,0,0,0-1.514.326.985.985,0,0,0-.464.9.905.905,0,0,0,.289.726A1.2,1.2,0,0,0,219.58,719.3Z"
          transform="translate(-176.444 -711.673)"
          fill="#595a5a"
        />
        <path
          id="Path_111"
          data-name="Path 111"
          d="M229.948,718.442a2.848,2.848,0,0,0,.45-.034,2.885,2.885,0,0,0,.345-.072v.673a1.6,1.6,0,0,1-.422.115,3.2,3.2,0,0,1-.5.044q-1.686,0-1.686-1.776v-3.458H227.3v-.424l.833-.366.371-1.241h.509v1.347H230.7v.684h-1.686v3.42a1.177,1.177,0,0,0,.249.806A.867.867,0,0,0,229.948,718.442Z"
          transform="translate(-180.414 -710.811)"
          fill="#595a5a"
        />
        <path
          id="Path_112"
          data-name="Path 112"
          d="M236.259,714.177v3.77a1.515,1.515,0,0,0,.323,1.061,1.319,1.319,0,0,0,1.013.35,1.654,1.654,0,0,0,1.334-.5,2.517,2.517,0,0,0,.421-1.628v-3.054h.881v5.812H239.5l-.127-.779h-.047a1.754,1.754,0,0,1-.751.657,2.519,2.519,0,0,1-1.1.228,2.22,2.22,0,0,1-1.588-.5,2.143,2.143,0,0,1-.527-1.612v-3.8Z"
          transform="translate(-183.703 -711.738)"
          fill="#595a5a"
        />
        <path
          id="Path_113"
          data-name="Path 113"
          d="M249.581,714a3.425,3.425,0,0,1,.695.064l-.122.816a2.974,2.974,0,0,0-.636-.079,1.55,1.55,0,0,0-1.207.572,2.09,2.09,0,0,0-.5,1.427v3.118h-.88V714.1h.726l.1,1.076h.042a2.55,2.55,0,0,1,.78-.874A1.757,1.757,0,0,1,249.581,714Z"
          transform="translate(-188.416 -711.665)"
          fill="#595a5a"
        />
        <path
          id="Path_114"
          data-name="Path 114"
          d="M257.177,720.022a2.671,2.671,0,0,1-2.034-.784,3.041,3.041,0,0,1-.745-2.18,3.37,3.37,0,0,1,.693-2.233,2.3,2.3,0,0,1,1.859-.827,2.191,2.191,0,0,1,1.729.719,2.755,2.755,0,0,1,.636,1.9v.557h-4a2.306,2.306,0,0,0,.517,1.554,1.792,1.792,0,0,0,1.381.53,4.686,4.686,0,0,0,1.856-.392v.785a4.618,4.618,0,0,1-.883.288A4.937,4.937,0,0,1,257.177,720.022Zm-.239-5.287a1.436,1.436,0,0,0-1.116.457,2.1,2.1,0,0,0-.49,1.262h3.038a1.938,1.938,0,0,0-.371-1.275A1.308,1.308,0,0,0,256.938,714.735Z"
          transform="translate(-191.46 -711.665)"
          fill="#595a5a"
        />
      </g>
      <g
        id="Group_30"
        data-name="Group 30"
        transform="translate(21.78 121.032)"
      >
        <path
          id="Path_115"
          data-name="Path 115"
          d="M143.815,769.12h-.9v-7.752h4.321v.8h-3.42v2.837h3.214v.8h-3.214Z"
          transform="translate(-142.914 -760.869)"
          fill="#595a5a"
        />
        <path
          id="Path_116"
          data-name="Path 116"
          d="M156.653,767.472a3.208,3.208,0,0,1-.716,2.219,2.521,2.521,0,0,1-1.978.8,2.618,2.618,0,0,1-1.384-.366,2.413,2.413,0,0,1-.933-1.05,3.658,3.658,0,0,1-.329-1.6,3.2,3.2,0,0,1,.71-2.214,2.514,2.514,0,0,1,1.973-.793,2.459,2.459,0,0,1,1.938.812A3.191,3.191,0,0,1,156.653,767.472Zm-4.428,0a2.754,2.754,0,0,0,.446,1.7,1.766,1.766,0,0,0,2.622,0,2.746,2.746,0,0,0,.448-1.7,2.7,2.7,0,0,0-.448-1.684,1.575,1.575,0,0,0-1.323-.575,1.547,1.547,0,0,0-1.3.567A2.726,2.726,0,0,0,152.225,767.472Z"
          transform="translate(-146.338 -762.132)"
          fill="#595a5a"
        />
        <path
          id="Path_117"
          data-name="Path 117"
          d="M164.1,764.644v3.77a1.514,1.514,0,0,0,.323,1.06,1.316,1.316,0,0,0,1.013.351,1.654,1.654,0,0,0,1.333-.5,2.517,2.517,0,0,0,.422-1.628v-3.055h.88v5.812h-.726l-.127-.779h-.047a1.761,1.761,0,0,1-.751.658,2.526,2.526,0,0,1-1.095.228,2.22,2.22,0,0,1-1.588-.5,2.141,2.141,0,0,1-.528-1.612v-3.8Z"
          transform="translate(-151.187 -762.205)"
          fill="#595a5a"
        />
        <path
          id="Path_118"
          data-name="Path 118"
          d="M177.422,764.465a3.486,3.486,0,0,1,.695.063l-.122.817a2.974,2.974,0,0,0-.636-.08,1.55,1.55,0,0,0-1.207.573,2.09,2.09,0,0,0-.5,1.426v3.118h-.88v-5.812h.727l.1,1.076h.043a2.548,2.548,0,0,1,.78-.875A1.759,1.759,0,0,1,177.422,764.465Z"
          transform="translate(-155.9 -762.132)"
          fill="#595a5a"
        />
        <path
          id="Path_119"
          data-name="Path 119"
          d="M184.135,768.909a2.855,2.855,0,0,0,.451-.034,3.062,3.062,0,0,0,.345-.072v.674a1.624,1.624,0,0,1-.422.114,3.305,3.305,0,0,1-.5.045q-1.687,0-1.686-1.776V764.4h-.832v-.424l.832-.366.371-1.241h.509v1.347h1.686v.684H183.2v3.42a1.176,1.176,0,0,0,.249.806A.867.867,0,0,0,184.135,768.909Z"
          transform="translate(-158.638 -761.278)"
          fill="#595a5a"
        />
        <path
          id="Path_120"
          data-name="Path 120"
          d="M193.621,768.777v-3.76a1.517,1.517,0,0,0-.324-1.06,1.316,1.316,0,0,0-1.012-.35,1.66,1.66,0,0,0-1.339.5,2.525,2.525,0,0,0-.422,1.634v3.038h-.88v-8.251h.88v2.5a5.524,5.524,0,0,1-.042.748h.053a1.792,1.792,0,0,1,.739-.66,2.394,2.394,0,0,1,1.1-.242,2.242,2.242,0,0,1,1.6.506,2.132,2.132,0,0,1,.533,1.609v3.791Z"
          transform="translate(-161.963 -760.526)"
          fill="#595a5a"
        />
        <path
          id="Path_121"
          data-name="Path 121"
          d="M205.666,769.12h-.9v-7.752h4.322v.8h-3.42v2.837h3.214v.8h-3.214Z"
          transform="translate(-168.126 -760.869)"
          fill="#595a5a"
        />
        <path
          id="Path_122"
          data-name="Path 122"
          d="M215.942,770.49a2.672,2.672,0,0,1-2.034-.785,3.037,3.037,0,0,1-.745-2.179,3.375,3.375,0,0,1,.692-2.233,2.3,2.3,0,0,1,1.859-.827,2.191,2.191,0,0,1,1.729.719,2.756,2.756,0,0,1,.636,1.9v.556h-4a2.3,2.3,0,0,0,.517,1.554,1.787,1.787,0,0,0,1.381.53,4.676,4.676,0,0,0,1.856-.392v.785a4.584,4.584,0,0,1-.883.288A4.935,4.935,0,0,1,215.942,770.49ZM215.7,765.2a1.436,1.436,0,0,0-1.116.457,2.1,2.1,0,0,0-.49,1.261h3.038a1.938,1.938,0,0,0-.371-1.275A1.308,1.308,0,0,0,215.7,765.2Z"
          transform="translate(-171.55 -762.132)"
          fill="#595a5a"
        />
        <path
          id="Path_123"
          data-name="Path 123"
          d="M227.288,770.39l-.175-.827h-.043a2.45,2.45,0,0,1-.867.74,2.652,2.652,0,0,1-1.079.194,1.942,1.942,0,0,1-1.355-.445,1.626,1.626,0,0,1-.49-1.268q0-1.76,2.816-1.845l.986-.032v-.361a1.471,1.471,0,0,0-.294-1.01,1.211,1.211,0,0,0-.941-.326,3.838,3.838,0,0,0-1.644.446l-.27-.674a4.138,4.138,0,0,1,.941-.365,4.076,4.076,0,0,1,1.026-.133,2.212,2.212,0,0,1,1.54.462,1.925,1.925,0,0,1,.5,1.48v3.966Zm-1.989-.62a1.788,1.788,0,0,0,1.291-.451,1.666,1.666,0,0,0,.47-1.262v-.525l-.881.037a3.2,3.2,0,0,0-1.514.326.984.984,0,0,0-.464.9.906.906,0,0,0,.289.726A1.2,1.2,0,0,0,225.3,769.77Z"
          transform="translate(-175.673 -762.139)"
          fill="#595a5a"
        />
        <path
          id="Path_124"
          data-name="Path 124"
          d="M235.667,768.909a2.855,2.855,0,0,0,.451-.034,3.035,3.035,0,0,0,.345-.072v.674a1.617,1.617,0,0,1-.422.114,3.3,3.3,0,0,1-.5.045q-1.686,0-1.686-1.776V764.4h-.833v-.424l.833-.366.371-1.241h.509v1.347h1.686v.684h-1.686v3.42a1.176,1.176,0,0,0,.249.806A.868.868,0,0,0,235.667,768.909Z"
          transform="translate(-179.644 -761.278)"
          fill="#595a5a"
        />
        <path
          id="Path_125"
          data-name="Path 125"
          d="M241.96,764.644v3.77a1.513,1.513,0,0,0,.323,1.06,1.317,1.317,0,0,0,1.013.351,1.656,1.656,0,0,0,1.333-.5,2.513,2.513,0,0,0,.421-1.628v-3.055h.881v5.812H245.2l-.127-.779h-.047a1.757,1.757,0,0,1-.751.658,2.528,2.528,0,0,1-1.095.228,2.223,2.223,0,0,1-1.589-.5,2.143,2.143,0,0,1-.527-1.612v-3.8Z"
          transform="translate(-182.925 -762.205)"
          fill="#595a5a"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M255.282,764.465a3.478,3.478,0,0,1,.695.063l-.122.817a2.975,2.975,0,0,0-.636-.08,1.55,1.55,0,0,0-1.207.573,2.089,2.089,0,0,0-.5,1.426v3.118h-.88v-5.812h.726l.1,1.076h.043a2.542,2.542,0,0,1,.78-.875A1.759,1.759,0,0,1,255.282,764.465Z"
          transform="translate(-187.638 -762.132)"
          fill="#595a5a"
        />
        <path
          id="Path_127"
          data-name="Path 127"
          d="M262.878,770.49a2.673,2.673,0,0,1-2.034-.785,3.039,3.039,0,0,1-.745-2.179,3.372,3.372,0,0,1,.692-2.233,2.3,2.3,0,0,1,1.859-.827,2.191,2.191,0,0,1,1.729.719,2.756,2.756,0,0,1,.636,1.9v.556h-4a2.3,2.3,0,0,0,.517,1.554,1.788,1.788,0,0,0,1.381.53,4.674,4.674,0,0,0,1.856-.392v.785a4.594,4.594,0,0,1-.883.288A4.941,4.941,0,0,1,262.878,770.49Zm-.238-5.288a1.438,1.438,0,0,0-1.117.457,2.1,2.1,0,0,0-.49,1.261h3.039a1.935,1.935,0,0,0-.371-1.275A1.308,1.308,0,0,0,262.64,765.2Z"
          transform="translate(-190.683 -762.132)"
          fill="#595a5a"
        />
      </g>
      <g id="Group_34" data-name="Group 34" transform="translate(0 143.367)">
        <g id="Group_33" data-name="Group 33">
          <g id="Group_32" data-name="Group 32">
            <g id="Group_31" data-name="Group 31">
              <path
                id="Rectangle_61"
                data-name="Rectangle 61"
                d="M7.257,0h327.58a7.257,7.257,0,0,1,7.257,7.257v11.28a7.256,7.256,0,0,1-7.256,7.256H7.257A7.257,7.257,0,0,1,0,18.536V7.257A7.257,7.257,0,0,1,7.257,0Z"
                fill="#f1f0f0"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_35"
        data-name="Group 35"
        transform="translate(27.888 150.868)"
      >
        <path
          id="Path_128"
          data-name="Path 128"
          d="M154.125,819.586h-.9v-7.752h4.321v.8h-3.42v2.837h3.214v.8h-3.214Z"
          transform="translate(-153.224 -811.277)"
          fill="#595a5a"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M162.043,812.05a.583.583,0,0,1,.149-.442.55.55,0,0,1,.737,0,.575.575,0,0,1,.154.44.584.584,0,0,1-.154.443.514.514,0,0,1-.366.146.507.507,0,0,1-.371-.146A.6.6,0,0,1,162.043,812.05Zm.955,7.387h-.881v-5.812H163Z"
          transform="translate(-156.819 -811.127)"
          fill="#595a5a"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M168.852,814.075h-1.48V819.2h-.88v-5.128h-1.04v-.4l1.04-.318v-.323q0-2.143,1.871-2.143a3.845,3.845,0,0,1,1.082.186l-.227.7a2.93,2.93,0,0,0-.87-.164.847.847,0,0,0-.737.332,1.852,1.852,0,0,0-.239,1.063v.376h1.48Z"
          transform="translate(-158.209 -810.893)"
          fill="#595a5a"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M173.852,819.375a2.812,2.812,0,0,0,.451-.035,3.053,3.053,0,0,0,.344-.071v.673a1.605,1.605,0,0,1-.421.114,3.236,3.236,0,0,1-.5.046q-1.686,0-1.686-1.777v-3.457h-.833v-.424l.833-.366.371-1.241h.509v1.347h1.686v.684h-1.686v3.42a1.174,1.174,0,0,0,.249.806A.865.865,0,0,0,173.852,819.375Z"
          transform="translate(-160.554 -811.685)"
          fill="#595a5a"
        />
        <path
          id="Path_132"
          data-name="Path 132"
          d="M183.339,819.243v-3.76a1.516,1.516,0,0,0-.324-1.06,1.319,1.319,0,0,0-1.012-.35,1.66,1.66,0,0,0-1.339.5,2.524,2.524,0,0,0-.422,1.633v3.038h-.88v-8.251h.88v2.5a5.417,5.417,0,0,1-.043.748h.053a1.8,1.8,0,0,1,.739-.661,2.4,2.4,0,0,1,1.1-.241,2.242,2.242,0,0,1,1.6.506,2.13,2.13,0,0,1,.533,1.609v3.792Z"
          transform="translate(-163.879 -810.933)"
          fill="#595a5a"
        />
        <path
          id="Path_133"
          data-name="Path 133"
          d="M196.539,819.586h-.9v-7.752h4.322v.8h-3.42v2.837h3.214v.8h-3.214Z"
          transform="translate(-170.513 -811.277)"
          fill="#595a5a"
        />
        <path
          id="Path_134"
          data-name="Path 134"
          d="M206.815,820.955a2.671,2.671,0,0,1-2.034-.785,3.035,3.035,0,0,1-.745-2.179,3.372,3.372,0,0,1,.692-2.233,2.3,2.3,0,0,1,1.858-.827,2.191,2.191,0,0,1,1.729.719,2.752,2.752,0,0,1,.636,1.9v.556h-4a2.3,2.3,0,0,0,.517,1.554,1.786,1.786,0,0,0,1.381.531,4.679,4.679,0,0,0,1.856-.393v.785a4.67,4.67,0,0,1-.883.289A4.975,4.975,0,0,1,206.815,820.955Zm-.239-5.287a1.437,1.437,0,0,0-1.116.456,2.1,2.1,0,0,0-.49,1.262h3.038a1.938,1.938,0,0,0-.371-1.275A1.308,1.308,0,0,0,206.576,815.669Z"
          transform="translate(-173.937 -812.539)"
          fill="#595a5a"
        />
        <path
          id="Path_135"
          data-name="Path 135"
          d="M218.161,820.856l-.175-.828h-.043a2.438,2.438,0,0,1-.867.74,2.631,2.631,0,0,1-1.079.194,1.942,1.942,0,0,1-1.355-.445,1.625,1.625,0,0,1-.49-1.268q0-1.76,2.816-1.845l.986-.032v-.36a1.47,1.47,0,0,0-.294-1.01,1.21,1.21,0,0,0-.941-.326,3.83,3.83,0,0,0-1.644.445l-.27-.674a4.238,4.238,0,0,1,1.967-.5,2.214,2.214,0,0,1,1.54.462,1.925,1.925,0,0,1,.5,1.48v3.966Zm-1.989-.62a1.787,1.787,0,0,0,1.291-.451,1.669,1.669,0,0,0,.47-1.262V818l-.881.037a3.2,3.2,0,0,0-1.514.326.984.984,0,0,0-.464.9.908.908,0,0,0,.289.727A1.2,1.2,0,0,0,216.172,820.236Z"
          transform="translate(-178.06 -812.546)"
          fill="#595a5a"
        />
        <path
          id="Path_136"
          data-name="Path 136"
          d="M226.54,819.375a2.8,2.8,0,0,0,.451-.035,3.067,3.067,0,0,0,.345-.071v.673a1.6,1.6,0,0,1-.422.114,3.223,3.223,0,0,1-.5.046q-1.686,0-1.686-1.777v-3.457h-.833v-.424l.833-.366.371-1.241h.509v1.347h1.686v.684h-1.686v3.42a1.174,1.174,0,0,0,.249.806A.867.867,0,0,0,226.54,819.375Z"
          transform="translate(-182.031 -811.685)"
          fill="#595a5a"
        />
        <path
          id="Path_137"
          data-name="Path 137"
          d="M232.833,815.11v3.77a1.516,1.516,0,0,0,.323,1.061,1.318,1.318,0,0,0,1.013.349,1.656,1.656,0,0,0,1.333-.5,2.514,2.514,0,0,0,.422-1.628V815.11h.88v5.812h-.727l-.127-.779H235.9a1.758,1.758,0,0,1-.751.658,2.527,2.527,0,0,1-1.095.228,2.222,2.222,0,0,1-1.589-.5,2.144,2.144,0,0,1-.527-1.612v-3.8Z"
          transform="translate(-185.312 -812.612)"
          fill="#595a5a"
        />
        <path
          id="Path_138"
          data-name="Path 138"
          d="M246.155,814.931a3.467,3.467,0,0,1,.695.063l-.122.817a2.986,2.986,0,0,0-.636-.079,1.548,1.548,0,0,0-1.206.573,2.083,2.083,0,0,0-.5,1.426v3.118h-.88v-5.812h.726l.1,1.077h.043a2.536,2.536,0,0,1,.78-.875A1.758,1.758,0,0,1,246.155,814.931Z"
          transform="translate(-190.025 -812.539)"
          fill="#595a5a"
        />
        <path
          id="Path_139"
          data-name="Path 139"
          d="M253.752,820.955a2.672,2.672,0,0,1-2.034-.785,3.037,3.037,0,0,1-.745-2.179,3.372,3.372,0,0,1,.692-2.233,2.3,2.3,0,0,1,1.859-.827,2.191,2.191,0,0,1,1.729.719,2.755,2.755,0,0,1,.636,1.9v.556h-4a2.3,2.3,0,0,0,.517,1.554,1.788,1.788,0,0,0,1.381.531,4.682,4.682,0,0,0,1.857-.393v.785a4.7,4.7,0,0,1-.883.289A4.984,4.984,0,0,1,253.752,820.955Zm-.239-5.287a1.439,1.439,0,0,0-1.117.456,2.106,2.106,0,0,0-.49,1.262h3.039a1.934,1.934,0,0,0-.371-1.275A1.307,1.307,0,0,0,253.513,815.669Z"
          transform="translate(-193.069 -812.539)"
          fill="#595a5a"
        />
      </g>
      <g
        id="Group_36"
        data-name="Group 36"
        transform="translate(114.031 23.227)"
      >
        <rect
          id="Rectangle_62"
          data-name="Rectangle 62"
          width="4.073"
          height="145.903"
          fill="#fff"
        />
      </g>
      <g
        id="Group_37"
        data-name="Group 37"
        transform="translate(228.741 23.227)"
      >
        <rect
          id="Rectangle_63"
          data-name="Rectangle 63"
          width="4.073"
          height="145.903"
          fill="#fff"
        />
      </g>
      <g id="Group_40" data-name="Group 40" transform="translate(21.398 0)">
        <path
          id="Path_140"
          data-name="Path 140"
          d="M148.7,560.215a3.818,3.818,0,0,1-1.089,2.932,4.445,4.445,0,0,1-3.147,1.018h-2.2v-7.752H144.7a4.1,4.1,0,0,1,2.948,1A3.686,3.686,0,0,1,148.7,560.215Zm-1.707.042q0-2.5-2.206-2.5h-.876v5.048h.706Q146.992,562.808,146.993,560.257Z"
          transform="translate(-142.268 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_141"
          data-name="Path 141"
          d="M160.468,564.165H156v-7.752h4.465v1.346h-2.821v1.7h2.625v1.347h-2.625v2h2.821Z"
          transform="translate(-147.867 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_142"
          data-name="Path 142"
          d="M170.537,561.936a2.005,2.005,0,0,1-.755,1.654,3.285,3.285,0,0,1-2.1.6,4.939,4.939,0,0,1-2.2-.467V562.2a9,9,0,0,0,1.329.493,3.908,3.908,0,0,0,.994.143,1.414,1.414,0,0,0,.83-.207.706.706,0,0,0,.289-.615.683.683,0,0,0-.127-.406,1.417,1.417,0,0,0-.374-.342,9.8,9.8,0,0,0-1-.525,4.747,4.747,0,0,1-1.066-.642,2.4,2.4,0,0,1-.567-.716,2.042,2.042,0,0,1-.212-.955,2,2,0,0,1,.7-1.617,2.9,2.9,0,0,1,1.927-.588,4.561,4.561,0,0,1,1.154.143,8.05,8.05,0,0,1,1.148.4l-.53,1.278a7.779,7.779,0,0,0-1.027-.355,3.315,3.315,0,0,0-.8-.1,1.055,1.055,0,0,0-.716.217.717.717,0,0,0-.249.568.7.7,0,0,0,.1.379,1.1,1.1,0,0,0,.32.313,9.993,9.993,0,0,0,1.042.544,4.154,4.154,0,0,1,1.49,1.042A2.034,2.034,0,0,1,170.537,561.936Z"
          transform="translate(-151.732 -556.214)"
          fill="#595a5a"
        />
        <path
          id="Path_143"
          data-name="Path 143"
          d="M179.663,557.593a1.677,1.677,0,0,0-1.437.7,3.245,3.245,0,0,0-.509,1.944q0,2.593,1.946,2.593a6.147,6.147,0,0,0,1.978-.409V563.8a5.5,5.5,0,0,1-2.131.4,3.253,3.253,0,0,1-2.588-1.026,4.344,4.344,0,0,1-.9-2.946,4.8,4.8,0,0,1,.441-2.118,3.181,3.181,0,0,1,1.264-1.4,3.747,3.747,0,0,1,1.933-.485,5.224,5.224,0,0,1,2.27.546l-.531,1.336a8.806,8.806,0,0,0-.875-.361A2.606,2.606,0,0,0,179.663,557.593Z"
          transform="translate(-156.028 -556.214)"
          fill="#595a5a"
        />
        <path
          id="Path_144"
          data-name="Path 144"
          d="M189.713,561.19v2.975h-1.644v-7.752h2.259a3.867,3.867,0,0,1,2.339.575,2.295,2.295,0,0,1,.382,2.964,2.494,2.494,0,0,1-1.066.835q1.75,2.613,2.28,3.378h-1.824l-1.851-2.975Zm0-1.336h.53a2.032,2.032,0,0,0,1.151-.259.928.928,0,0,0,.371-.817.849.849,0,0,0-.379-.785,2.3,2.3,0,0,0-1.175-.233h-.5Z"
          transform="translate(-160.938 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_145"
          data-name="Path 145"
          d="M200.667,564.165v-7.752h1.644v7.752Z"
          transform="translate(-166.073 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_146"
          data-name="Path 146"
          d="M211.744,558.831a2.37,2.37,0,0,1-.783,1.914,3.342,3.342,0,0,1-2.224.663h-.7v2.758h-1.644v-7.752h2.477a3.292,3.292,0,0,1,2.145.607A2.214,2.214,0,0,1,211.744,558.831Zm-3.712,1.23h.541a1.8,1.8,0,0,0,1.134-.3,1.049,1.049,0,0,0,.377-.872,1.075,1.075,0,0,0-.316-.854,1.479,1.479,0,0,0-.989-.276h-.748Z"
          transform="translate(-168.405 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_147"
          data-name="Path 147"
          d="M220.332,564.165h-1.644v-6.385h-2.105v-1.368h5.854v1.368h-2.105Z"
          transform="translate(-172.561 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_148"
          data-name="Path 148"
          d="M228.169,564.165v-7.752h1.644v7.752Z"
          transform="translate(-177.284 -556.291)"
          fill="#595a5a"
        />
        <path
          id="Path_149"
          data-name="Path 149"
          d="M240.689,560.195a4.208,4.208,0,0,1-.954,2.959,4.139,4.139,0,0,1-5.473,0,4.225,4.225,0,0,1-.954-2.97,4.163,4.163,0,0,1,.957-2.956,3.579,3.579,0,0,1,2.744-1.021,3.528,3.528,0,0,1,2.733,1.028A4.22,4.22,0,0,1,240.689,560.195Zm-5.658,0a3.238,3.238,0,0,0,.493,1.956,1.726,1.726,0,0,0,1.474.657q1.966,0,1.967-2.613t-1.957-2.62a1.737,1.737,0,0,0-1.48.661A3.224,3.224,0,0,0,235.031,560.195Z"
          transform="translate(-179.379 -556.207)"
          fill="#595a5a"
        />
        <path
          id="Path_150"
          data-name="Path 150"
          d="M255.671,564.165h-2.09l-3.372-5.865h-.048q.1,1.554.1,2.216v3.649h-1.469v-7.752h2.073l3.367,5.806h.037q-.079-1.512-.079-2.137v-3.669h1.48Z"
          transform="translate(-185.691 -556.291)"
          fill="#595a5a"
        />
      </g>
      <g
        id="Group_41"
        data-name="Group 41"
        transform="translate(165.617 29.336)"
      >
        <path
          id="Path_151"
          data-name="Path 151"
          d="M392.516,619.3a6.787,6.787,0,1,1,6.788-6.787A6.795,6.795,0,0,1,392.516,619.3Zm0-12.9a6.109,6.109,0,1,0,6.109,6.109A6.116,6.116,0,0,0,392.516,606.408Z"
          transform="translate(-385.729 -605.73)"
          fill="#7f8080"
        />
      </g>
      <g
        id="Group_42"
        data-name="Group 42"
        transform="translate(165.617 59.88)"
      >
        <path
          id="Path_152"
          data-name="Path 152"
          d="M392.516,670.867a6.787,6.787,0,1,1,6.788-6.787A6.8,6.8,0,0,1,392.516,670.867Zm0-12.9a6.109,6.109,0,1,0,6.109,6.109A6.116,6.116,0,0,0,392.516,657.971Z"
          transform="translate(-385.729 -657.292)"
          fill="#7f8080"
        />
      </g>
      <g
        id="Group_43"
        data-name="Group 43"
        transform="translate(165.617 89.745)"
      >
        <path
          id="Path_153"
          data-name="Path 153"
          d="M392.516,721.283A6.788,6.788,0,1,1,399.3,714.5,6.8,6.8,0,0,1,392.516,721.283Zm0-12.9a6.109,6.109,0,1,0,6.109,6.109A6.116,6.116,0,0,0,392.516,708.387Z"
          transform="translate(-385.729 -707.708)"
          fill="#7f8080"
        />
      </g>
      <g
        id="Group_44"
        data-name="Group 44"
        transform="translate(165.617 119.61)"
      >
        <path
          id="Path_154"
          data-name="Path 154"
          d="M392.516,772.379a7.136,7.136,0,1,1,6.788-7.127A6.971,6.971,0,0,1,392.516,772.379Zm0-13.576a6.458,6.458,0,1,0,6.109,6.449A6.293,6.293,0,0,0,392.516,758.8Z"
          transform="translate(-385.729 -758.125)"
          fill="#7f8080"
        />
      </g>
      <g
        id="Group_45"
        data-name="Group 45"
        transform="translate(165.617 149.476)"
      >
        <path
          id="Path_155"
          data-name="Path 155"
          d="M392.516,822.8a7.136,7.136,0,1,1,6.788-7.127A6.972,6.972,0,0,1,392.516,822.8Zm0-13.575a6.457,6.457,0,1,0,6.109,6.448A6.292,6.292,0,0,0,392.516,809.221Z"
          transform="translate(-385.729 -808.542)"
          fill="#d85414"
        />
      </g>
      <g
        id="Group_46"
        data-name="Group 46"
        transform="translate(283.041 29.336)"
      >
        <path
          id="Path_156"
          data-name="Path 156"
          d="M590.745,619.3a6.787,6.787,0,1,1,6.788-6.787A6.8,6.8,0,0,1,590.745,619.3Zm0-12.9a6.109,6.109,0,1,0,6.109,6.109A6.116,6.116,0,0,0,590.745,606.408Z"
          transform="translate(-583.958 -605.73)"
          fill="#7f8080"
        />
      </g>
      <g
        id="Group_47"
        data-name="Group 47"
        transform="translate(283.041 59.88)"
      >
        <path
          id="Path_157"
          data-name="Path 157"
          d="M590.745,670.867a6.787,6.787,0,1,1,6.788-6.787A6.8,6.8,0,0,1,590.745,670.867Zm0-12.9a6.109,6.109,0,1,0,6.109,6.109A6.116,6.116,0,0,0,590.745,657.971Z"
          transform="translate(-583.958 -657.292)"
          fill="#7f8080"
        />
      </g>
      <g
        id="Group_48"
        data-name="Group 48"
        transform="translate(283.041 89.745)"
      >
        <path
          id="Path_158"
          data-name="Path 158"
          d="M590.745,721.283a6.788,6.788,0,1,1,6.788-6.787A6.8,6.8,0,0,1,590.745,721.283Zm0-12.9a6.109,6.109,0,1,0,6.109,6.109A6.116,6.116,0,0,0,590.745,708.387Z"
          transform="translate(-583.958 -707.708)"
          fill="#eb641c"
        />
      </g>
      <g
        id="Group_49"
        data-name="Group 49"
        transform="translate(283.041 119.61)"
      >
        <path
          id="Path_159"
          data-name="Path 159"
          d="M590.745,772.379a7.136,7.136,0,1,1,6.788-7.127A6.972,6.972,0,0,1,590.745,772.379Zm0-13.576a6.458,6.458,0,1,0,6.109,6.449A6.293,6.293,0,0,0,590.745,758.8Z"
          transform="translate(-583.958 -758.125)"
          fill="#7f8080"
        />
      </g>
      <g
        id="Group_50"
        data-name="Group 50"
        transform="translate(283.041 149.476)"
      >
        <path
          id="Path_160"
          data-name="Path 160"
          d="M590.745,822.8a7.136,7.136,0,1,1,6.788-7.127A6.972,6.972,0,0,1,590.745,822.8Zm0-13.575a6.457,6.457,0,1,0,6.109,6.448A6.292,6.292,0,0,0,590.745,809.221Z"
          transform="translate(-583.958 -808.542)"
          fill="#eb641c"
        />
      </g>
    </svg>

    <!-- 62 -->
    <svg
      v-if="name == '62'"
      xmlns="http://www.w3.org/2000/svg"
      width="270"
      height="169"
      viewBox="0 0 270 169"
    >
      <g
        id="Group_62"
        data-name="Group 62"
        transform="translate(-0.311 -0.308)"
      >
        <g
          id="Rectangle_70"
          data-name="Rectangle 70"
          transform="translate(0.311 0.308)"
          fill="none"
          stroke="#eee"
          stroke-width="1"
        >
          <rect width="129" height="169" rx="9" stroke="none" />
          <rect x="0.5" y="0.5" width="128" height="168" rx="8.5" fill="none" />
        </g>
        <g
          id="Rectangle_75"
          data-name="Rectangle 75"
          transform="translate(140.311 0.308)"
          fill="none"
          stroke="#eee"
          stroke-width="1"
        >
          <rect width="130" height="169" rx="9" stroke="none" />
          <rect x="0.5" y="0.5" width="129" height="168" rx="8.5" fill="none" />
        </g>
        <rect
          id="Rectangle_71"
          data-name="Rectangle 71"
          width="102"
          height="28"
          rx="10"
          transform="translate(14.311 23.308)"
          fill="#f1f0f0"
        />
        <rect
          id="Rectangle_76"
          data-name="Rectangle 76"
          width="100"
          height="61"
          rx="10"
          transform="translate(156.311 23.308)"
          fill="#f1f0f0"
        />
        <rect
          id="Rectangle_77"
          data-name="Rectangle 77"
          width="100"
          height="11"
          rx="5.5"
          transform="translate(156.311 91.308)"
          fill="#f1f0f0"
        />
        <rect
          id="Rectangle_72"
          data-name="Rectangle 72"
          width="51"
          height="28"
          rx="10"
          transform="translate(14.311 55.308)"
          fill="#f1f0f0"
        />
        <rect
          id="Rectangle_73"
          data-name="Rectangle 73"
          width="102"
          height="60"
          rx="10"
          transform="translate(14.311 87.308)"
          fill="#f1f0f0"
        />
        <g id="plus" transform="translate(55.257 59.445)">
          <path
            id="Path_172"
            data-name="Path 172"
            d="M10.551,0A10.551,10.551,0,1,0,21.1,10.551,10.562,10.562,0,0,0,10.551,0Zm0,0"
            fill="#f39819"
          />
          <path
            id="Path_173"
            data-name="Path 173"
            d="M132.779,129.042h-3.737v3.737a.879.879,0,0,1-1.758,0v-3.737h-3.737a.879.879,0,1,1,0-1.758h3.737v-3.737a.879.879,0,0,1,1.758,0v3.737h3.737a.879.879,0,0,1,0,1.758Zm0,0"
            transform="translate(-117.612 -117.612)"
            fill="#fafafa"
          />
        </g>
        <g id="plus-2" data-name="plus" transform="translate(235.936 85.821)">
          <path
            id="Path_172-2"
            data-name="Path 172"
            d="M10.551,0A10.551,10.551,0,1,0,21.1,10.551,10.562,10.562,0,0,0,10.551,0Zm0,0"
            fill="#f39819"
          />
          <path
            id="Path_173-2"
            data-name="Path 173"
            d="M132.779,129.042h-3.737v3.737a.879.879,0,0,1-1.758,0v-3.737h-3.737a.879.879,0,1,1,0-1.758h3.737v-3.737a.879.879,0,0,1,1.758,0v3.737h3.737a.879.879,0,0,1,0,1.758Zm0,0"
            transform="translate(-117.612 -117.612)"
            fill="#fafafa"
          />
        </g>
      </g>
    </svg>
    <!-- eslint-disable-next-line max-len -->
    <!-- 77 -->
    <svg
      v-if="name == '77'"
      id="Group_77"
      data-name="Group 77"
      xmlns="http://www.w3.org/2000/svg"
      width="250.593"
      height="169.159"
      viewBox="0 0 250.593 169.159"
    >
      <g id="Group_69" data-name="Group 69" transform="translate(0 0)">
        <g id="Group_65" data-name="Group 65">
          <rect
            id="Rectangle_78"
            data-name="Rectangle 78"
            width="7.33"
            height="0.878"
            transform="translate(37.362)"
            fill="#f39819"
          />
          <rect
            id="Rectangle_79"
            data-name="Rectangle 79"
            width="16.689"
            height="0.878"
            transform="translate(13.569)"
            fill="#f39819"
          />
          <g id="Group_63" data-name="Group 63" transform="translate(0 3.045)">
            <path
              id="Path_174"
              data-name="Path 174"
              d="M181.72,154.428H90.3A11.691,11.691,0,0,1,78.9,143.879L74.321,87.67A9.6,9.6,0,0,1,84.01,77.122h91.417A11.69,11.69,0,0,1,186.833,87.67l4.576,56.209A9.605,9.605,0,0,1,181.72,154.428Z"
              transform="translate(-73.846 -76.683)"
              fill="#fff"
            />
            <path
              id="Path_175"
              data-name="Path 175"
              d="M181.726,154.874H90.309a12.116,12.116,0,0,1-11.844-10.953L73.89,87.712A10.042,10.042,0,0,1,84.016,76.689h91.416A12.116,12.116,0,0,1,187.278,87.64l4.576,56.21a10.045,10.045,0,0,1-10.128,11.024ZM84.016,77.567A9.166,9.166,0,0,0,74.765,87.64l4.576,56.21a11.22,11.22,0,0,0,10.968,10.145h91.417a9.164,9.164,0,0,0,9.251-10.074L186.4,87.712a11.223,11.223,0,0,0-10.97-10.145Z"
              transform="translate(-73.853 -76.689)"
              fill="#f1f0f0"
            />
          </g>
          <g
            id="Group_64"
            data-name="Group 64"
            transform="translate(8.353 14.828)"
          >
            <path
              id="Path_176"
              data-name="Path 176"
              d="M82.472,93.084h48.443l-.39-4.786H82.083Z"
              transform="translate(-82.083 -88.299)"
              fill="#e0e0e0"
            />
            <path
              id="Path_177"
              data-name="Path 177"
              d="M178.647,93.084H130.2l-.39-4.786h48.443Z"
              transform="translate(-81.372 -88.299)"
              fill="#f39819"
            />
            <path
              id="Path_178"
              data-name="Path 178"
              d="M83.472,105.36h13.82l-.39-4.786H83.082Z"
              transform="translate(-82.068 -88.116)"
              fill="#e0e0e0"
            />
            <path
              id="Path_179"
              data-name="Path 179"
              d="M180.154,105.36H97.089l-.39-4.786h83.066Z"
              transform="translate(-81.865 -88.116)"
              fill="#f39819"
            />
            <path
              id="Path_180"
              data-name="Path 180"
              d="M84.471,117.635h43.12l-.39-4.786H84.081Z"
              transform="translate(-82.053 -87.933)"
              fill="#e0e0e0"
            />
            <path
              id="Path_181"
              data-name="Path 181"
              d="M180.724,117.635H126.958l-.39-4.786h53.766Z"
              transform="translate(-81.42 -87.933)"
              fill="#f39819"
            />
            <path
              id="Path_182"
              data-name="Path 182"
              d="M85.47,129.911h73.592l-.39-4.786H85.081Z"
              transform="translate(-82.038 -87.751)"
              fill="#e0e0e0"
            />
            <path
              id="Path_183"
              data-name="Path 183"
              d="M181.276,129.911H157.983l-.39-4.786h23.293Z"
              transform="translate(-80.959 -87.751)"
              fill="#f39819"
            />
            <path
              id="Path_184"
              data-name="Path 184"
              d="M86.47,142.187h34.037l-.39-4.786H86.08Z"
              transform="translate(-82.023 -87.568)"
              fill="#e0e0e0"
            />
            <path
              id="Path_185"
              data-name="Path 185"
              d="M182.856,142.187H120.007l-.39-4.786h62.849Z"
              transform="translate(-81.524 -87.568)"
              fill="#f39819"
            />
          </g>
        </g>
        <g
          id="Group_68"
          data-name="Group 68"
          transform="translate(124.97 3.045)"
        >
          <g id="Group_66" data-name="Group 66">
            <path
              id="Path_186"
              data-name="Path 186"
              d="M304.857,154.428H213.441a11.69,11.69,0,0,1-11.406-10.549L197.458,87.67a9.6,9.6,0,0,1,9.689-10.548h91.416A11.69,11.69,0,0,1,309.97,87.67l4.576,56.209A9.6,9.6,0,0,1,304.857,154.428Z"
              transform="translate(-196.983 -76.683)"
              fill="#fff"
            />
            <path
              id="Path_187"
              data-name="Path 187"
              d="M304.863,154.874H213.447A12.116,12.116,0,0,1,201.6,143.921l-4.576-56.209a10.042,10.042,0,0,1,10.127-11.023H298.57A12.115,12.115,0,0,1,310.415,87.64l4.576,56.21a10.045,10.045,0,0,1-10.128,11.024Zm-97.71-77.307A9.166,9.166,0,0,0,197.9,87.64l4.576,56.21a11.222,11.222,0,0,0,10.969,10.145h91.416a9.166,9.166,0,0,0,9.251-10.074l-4.575-56.209A11.221,11.221,0,0,0,298.57,77.567Z"
              transform="translate(-196.99 -76.689)"
              fill="#f1f0f0"
            />
          </g>
          <g
            id="Group_67"
            data-name="Group 67"
            transform="translate(12.317 11.783)"
          >
            <path
              id="Path_188"
              data-name="Path 188"
              d="M258.217,142.918H256.6L252.157,88.3h1.613Z"
              transform="translate(-208.485 -88.299)"
              fill="#e0e0e0"
            />
            <path
              id="Path_189"
              data-name="Path 189"
              d="M260.7,120.066H250.523l-1.737-21.34h10.181Z"
              transform="translate(-208.535 -88.144)"
              fill="#f39819"
            />
            <path
              id="Path_190"
              data-name="Path 190"
              d="M238.474,142.918h-1.613L232.414,88.3h1.613Z"
              transform="translate(-208.779 -88.299)"
              fill="#e0e0e0"
            />
            <path
              id="Path_191"
              data-name="Path 191"
              d="M241.337,124.7H231.156l-1.523-18.706h10.181Z"
              transform="translate(-208.82 -88.035)"
              fill="#f39819"
            />
            <path
              id="Path_192"
              data-name="Path 192"
              d="M218.731,142.918h-1.614L212.671,88.3h1.613Z"
              transform="translate(-209.073 -88.299)"
              fill="#e0e0e0"
            />
            <path
              id="Path_193"
              data-name="Path 193"
              d="M220.352,109.448H210.171L209.126,96.6h10.181Z"
              transform="translate(-209.126 -88.175)"
              fill="#35383a"
            />
            <path
              id="Path_194"
              data-name="Path 194"
              d="M277.961,142.918h-1.613L271.9,88.3h1.613Z"
              transform="translate(-208.191 -88.299)"
              fill="#e0e0e0"
            />
            <path
              id="Path_195"
              data-name="Path 195"
              d="M281.433,132.18H271.252l-2.724-33.454H278.71Z"
              transform="translate(-208.241 -88.144)"
              fill="#35383a"
            />
            <path
              id="Path_196"
              data-name="Path 196"
              d="M297.7,142.918h-1.614L291.644,88.3h1.614Z"
              transform="translate(-207.897 -88.299)"
              fill="#e0e0e0"
            />
            <path
              id="Path_197"
              data-name="Path 197"
              d="M301.49,136.032H291.309l-2.181-26.792h10.181Z"
              transform="translate(-207.935 -87.987)"
              fill="#f39819"
            />
          </g>
        </g>
      </g>
      <g id="Group_76" data-name="Group 76" transform="translate(7.104 88.295)">
        <g id="Group_72" data-name="Group 72" transform="translate(125.45 0)">
          <g id="Group_70" data-name="Group 70">
            <path
              id="Path_198"
              data-name="Path 198"
              d="M306.843,172.961H215.427a11.691,11.691,0,0,1-11.407-10.548l-4.576-56.21a9.6,9.6,0,0,1,9.689-10.548H300.55A11.69,11.69,0,0,1,311.956,106.2l4.576,56.21A9.6,9.6,0,0,1,306.843,172.961Z"
              transform="translate(-198.968 -95.216)"
              fill="#fff"
            />
            <path
              id="Path_199"
              data-name="Path 199"
              d="M306.849,173.406H215.433a12.113,12.113,0,0,1-11.845-10.951l-4.577-56.209a10.044,10.044,0,0,1,10.128-11.024h91.417A12.115,12.115,0,0,1,312.4,106.174l4.577,56.211a10.042,10.042,0,0,1-10.128,11.022Zm-97.71-77.3a9.164,9.164,0,0,0-9.251,10.073l4.576,56.211a11.221,11.221,0,0,0,10.969,10.144h91.416a9.164,9.164,0,0,0,9.251-10.073l-4.576-56.209A11.221,11.221,0,0,0,300.556,96.1Z"
              transform="translate(-198.975 -95.222)"
              fill="#f1f0f0"
            />
          </g>
          <g
            id="Group_71"
            data-name="Group 71"
            transform="translate(27.528 7.644)"
          >
            <path
              id="Path_200"
              data-name="Path 200"
              d="M281.041,156.308a28.351,28.351,0,0,1-13.436,8.14l-1.513-4.335c11.255-3.076,18.875-13.571,17.859-26.044A29.343,29.343,0,0,0,274.505,115l2.915-3.174C290.614,124.032,292.249,144.108,281.041,156.308Z"
              transform="translate(-225.503 -102.619)"
              fill="#e0e0e0"
            />
            <path
              id="Path_201"
              data-name="Path 201"
              d="M277.906,111.966l-2.915,3.174a29.157,29.157,0,0,0-19.708-7.894,24.7,24.7,0,0,0-18.413,7.9l-3.442-3.183a28.966,28.966,0,0,1,21.489-9.212A34.11,34.11,0,0,1,277.906,111.966Z"
              transform="translate(-225.99 -102.754)"
              fill="#263238"
            />
            <path
              id="Path_202"
              data-name="Path 202"
              d="M268.2,164.449c-10.6,2.9-22.458-.211-31.041-8.14-13.185-12.2-14.819-32.276-3.621-44.477l3.442,3.183a24.882,24.882,0,0,0-6.342,19.057c1.211,14.881,14.262,26.95,29.144,26.95a26.4,26.4,0,0,0,6.906-.906Z"
              transform="translate(-226.099 -102.619)"
              fill="#f39819"
            />
          </g>
        </g>
        <g id="Group_75" data-name="Group 75" transform="translate(0 2.68)">
          <g id="Group_73" data-name="Group 73">
            <path
              id="Path_203"
              data-name="Path 203"
              d="M183.232,175.6H91.816a11.691,11.691,0,0,1-11.407-10.549l-4.576-56.209a9.605,9.605,0,0,1,9.689-10.549h91.417a11.69,11.69,0,0,1,11.406,10.549l4.576,56.209A9.605,9.605,0,0,1,183.232,175.6Z"
              transform="translate(-75.358 -97.857)"
              fill="#fff"
            />
            <path
              id="Path_204"
              data-name="Path 204"
              d="M183.238,176.047H91.822A12.116,12.116,0,0,1,79.977,165.1L75.4,108.886A10.042,10.042,0,0,1,85.529,97.863h91.416a12.116,12.116,0,0,1,11.845,10.952l4.575,56.209a10.042,10.042,0,0,1-10.127,11.023ZM85.529,98.741a9.164,9.164,0,0,0-9.252,10.074l4.576,56.209a11.222,11.222,0,0,0,10.969,10.145h91.416a9.167,9.167,0,0,0,9.251-10.073l-4.576-56.21a11.22,11.22,0,0,0-10.968-10.145Z"
              transform="translate(-75.365 -97.863)"
              fill="#f1f0f0"
            />
          </g>
          <g
            id="Group_74"
            data-name="Group 74"
            transform="translate(17.331 12.397)"
          >
            <path
              id="Path_205"
              data-name="Path 205"
              d="M97.642,135.237,117.345,158l22.595-34.853,13.973,18.011,3.41-5.259,22.839,27.373-87.719-.033Z"
              transform="translate(-92.441 -109.884)"
              fill="#f39819"
            />

            <path
              id="Path_206"
              data-name="Path 206"
              d="M163.417,136.844l-9.157,19.609-29.208-46.374L111.3,139.529l-4.408-7L92.444,163.467l87.713-.044Z"
              transform="translate(-92.441 -110.079)"
              fill="#e0e0e0"
            />
          </g>
        </g>
      </g>
    </svg>

    <!-- 78 -->
    <svg
      v-if="name == '78'"
      id="Group_78"
      data-name="Group 78"
      xmlns="http://www.w3.org/2000/svg"
      width="88.361"
      height="13.188"
      viewBox="0 0 88.361 13.188"
    >
      <path
        id="Polygon_1"
        data-name="Polygon 1"
        d="M6.594,0l6.594,11.869H0Z"
        transform="translate(88.361 0) rotate(90)"
        fill="#c9c6c6"
      />
      <line
        id="Line_9"
        data-name="Line 9"
        x2="76.492"
        transform="translate(0 6.594)"
        fill="none"
        stroke="#c9c6c6"
        stroke-width="2"
      />
    </svg>

    <!-- 79 -->
    <svg
      v-if="name == '79'"
      id="Group_79"
      data-name="Group 79"
      xmlns="http://www.w3.org/2000/svg"
      width="88.361"
      height="13.188"
      viewBox="0 0 88.361 13.188"
    >
      <path
        id="Polygon_1"
        data-name="Polygon 1"
        d="M6.594,0l6.594,11.869H0Z"
        transform="translate(88.361 0) rotate(90)"
        fill="#c9c6c6"
      />
      <line
        id="Line_9"
        data-name="Line 9"
        x2="76.492"
        transform="translate(0 6.594)"
        fill="none"
        stroke="#c9c6c6"
        stroke-width="2"
      />
    </svg>
    <svg
      v-if="name == upload"
      xmlns="http://www.w3.org/2000/svg"
      width="273"
      height="301"
      viewBox="0 0 273 301"
    >
      <g
        id="Group_2586"
        data-name="Group 2586"
        transform="translate(-1012 -363)"
      >
        <g id="Group_2431" data-name="Group 2431" transform="translate(0 12)">
          <g
            id="Group_2429"
            data-name="Group 2429"
            transform="translate(20 -12)"
          >
            <circle
              id="Ellipse_179"
              data-name="Ellipse 179"
              cx="82.5"
              cy="82.5"
              r="82.5"
              transform="translate(1046 413)"
              fill="#eaeaea"
            />
            <g id="Upload" transform="translate(1106 470)">
              <path
                id="ic_vertical_align_top_24px"
                d="M13.778,24.165h8.54V52.633h5.694V24.165h8.54L25.165,12.778Z"
                transform="translate(-2.261 -12.778)"
                fill="#a7a7a7"
              />
              <path
                id="ic_space_bar_24px"
                d="M43.855,9V20.387H9.694V9H4V26.081H49.548V9Z"
                transform="translate(-4 25.808)"
                fill="#a7a7a7"
              />
            </g>
          </g>
          <text
            id="Upload_Dashboard_Logo"
            data-name="Upload Dashboard Logo"
            transform="translate(1066 597)"
            fill="#a7a7a7"
            font-size="15"
            font-family="DMSans-Bold, DM Sans"
            font-weight="700"
          >
            <tspan x="0" y="0">Upload Dashboard Logo</tspan>
          </text>
        </g>
        <g
          id="Rectangle_2918"
          data-name="Rectangle 2918"
          transform="translate(1012 363)"
          fill="none"
          stroke="#d3d3d3"
          stroke-width="1"
        >
          <rect width="273" height="301" stroke="none" />
          <rect x="0.5" y="0.5" width="272" height="300" fill="none" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'customDashboardSvg',
  props: ['name', 'upload'],
};
</script>

<style></style>
